@import "../variables";

.card {
  width: 100%;
  border-radius: 0.25rem;
  display: inline-block;

  .card-content {
    .content {
      margin-top: 20px;
      color: $palette-gray;
      font-weight: 300;
    }
  }


  &.card-profile {
    margin-top: 30px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    .card-avatar {
      max-width: 130px;
      max-height: 130px;
      margin: -50px auto 0;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    }
    .card-content {
      text-align: center;

      .title, .subtitle {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 0.9rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $palette-gray;
      }
    }
    .card-footer {
      .card-footer-item {
        transition: color 200ms;
      }

    }
  }
}

.card {
  &.picture-card {
    margin-bottom: 3rem;
    border: 0;
    box-shadow: none;
    .card-image {
      img {
        width: 100%;
        border-radius: 6px;
        pointer-events: none;
        box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      }
    }
    .card-header {
      display: block;
      box-shadow: none;
      .card-header-category {
        width: 100%;
        margin-top: 10px;
        font-size: .75rem;
        text-transform: uppercase;
        font-weight: 600;
      }
      .card-header-title {
        color: $palette-shades-dark;
        &:hover {
          text-decoration: underline;
          border-color: $palette-shades-dark;
        }
      }
    }
  }
}

.card-blog-grid {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-radius: 1em;
  margin-bottom: 30px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease, -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1);

  &:hover {
    box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
  }
  .card-image {
    .image {
      img {
        object-fit: cover;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
      }
    }
  }
  .card-content {
    flex-grow: 2;
    .media {
      .media-content {
        overflow: hidden;
      }
      .title {
        font-weight: 400;
      }
    }
    .content {
      padding-left: 0;
    }
  }
  .card-footer {
    padding: 20px;
    border-color: $white-ter;
    .card-footer-item {
      padding: 0;
      flex-basis: auto;
      border: none;
      color: $palette-gray;
      font-size: $size-7;

      span {
        color: $font-color-content-primary;
        padding-left: 8px;
        font-weight: $weight-light;
      }
    }
  }
}