@import "variables";

$primary: $palette-primary;
$body-background-color: $palette-shades-light;

$title-color: $palette-shades-dark;
$link: $palette-accent-light;
$link-hover: $palette-shades-dark;

$info: $palette-shades-dark;


html {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  color: $palette-shades-dark;
}

p {
  text-align: justify;
}

footer {
  flex-shrink: 0;
}

.content {
  margin-top: 50px;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: 300;
}

