@import "../variables";

$sharing-button-size: 50px;

.fa-linkedin:hover {
  color: $brand-linkedin;
}

.fa-xing-square:hover {
  color: $brand-xing;
}

.fa-stack-overflow:hover {
  color: $brand-stack-overflow;
}

.fa-github-square:hover {
  color: $brand-github;
}

.fa-twitter-square:hover {
  color: $brand-twitter;
}

.single-blog-post {
  padding-bottom: 50px;
  .sharing-buttons {
    margin-top: -$sharing-button-size/2;
    text-align: center;

    .button {
      border: none;
      width: $sharing-button-size;
      height: $sharing-button-size;

      &.facebook {
        background-color: $brand-facebook;
      }

      &.twitter {
        background-color: $brand-twitter;
      }
    }
  }
}