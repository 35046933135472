@import "../variables";

.circle-image {
  text-align: center;
  img {
    border-radius: 50%;
  }
}

.is-darker-20 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.has-bg-img {
  background-size: cover;
}

.is-bg-img {
  display: none;
}