@import "../../node_modules/bulma/sass/utilities/initial-variables";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

$palette-primary: #FDD835;
$palette-accent-dark: #CF4143;
$palette-accent-light: #86AFBA;
$palette-shades-light: #FDFEFE;
$palette-shades-dark: #3F4355;
$palette-gray: #777;
$palette-gray-light: #aebbc5;

$brand-facebook: #3b5998;
$brand-twitter: #55acee;
$brand-linkedin: #1da1f2;
$brand-xing: #026466;
$brand-stack-overflow: #f48024;
$brand-github: #4078c0;

/*
$palette-primary: #FDD835;
$palette-primary-light: #FFFF6B;
$palette-primary-dark: #C6A700;

$palette-secondary: #EEEEEE;
$palette-secondary-light: #FFFFFF;
$palette-secondary-dark: #BCBCBC;
*/

///
/// Fonts
///

$font-color-headline: #202020;
$font-color-content-primary: #303030;
$font-color-content-secondary: #777;
