@import "../variables";

$footer-background-color: $palette-shades-dark;

footer {
  color: $palette-shades-light;

  .title {
    &.is-5 {
      color: $palette-shades-light;
      font-weight: 400;
    }
  }

  a {
    padding-right: 5px;
    color: $palette-gray-light;

    &:hover {
      color: $palette-accent-light;
    }
  }
}
