@import "../variables";

table {
    &.is-table-centered {
        margin-left: auto;
        margin-right: auto;
    }
    &.is-table-space-saving {
        width: auto !important;
    }
}