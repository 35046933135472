@import "../variables";

$navbar-background-color: $palette-primary;

.navbar {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  .navbar-item {
    color: $palette-shades-dark;
    fill: $palette-shades-dark;

    &:hover {
      background-color: #8c8150a6;
      color: $palette-shades-light;
      fill: $palette-shades-light;
    }
  }
}